.detailsAI {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.detailsBlockAI {
    align-items: center;
    width: 200px
}

#subTitleAI {
    font-size: 13px;
    margin-top: -5px;
    margin-bottom: 30px;
}

.buttonsAI {
    margin-bottom: 10px;
}

#bt {
    background-color: #EDEDED;
    border-width: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 7px;
    color: black;
    font-size: 13px;
}

#bf {
    background: none;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: black;
    font-size: 13px;
}

.descImgAI {
    display: none;
}

.genResContainerAI {
    margin-bottom: 10px;
}

#genArchDescriptionAIDesktop {
    font-size: 13px;
    width: 350px;
    height: 200px;
}

.ImgContainerAI {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ImgContainerAISize {
    height: 300px;
    margin-top: -50px;
}

#imageAI {
    width: 270px;
    text-decoration: none;
    align-self: center;
}

@media only screen and (max-width: 1000px) { 
    #imageAI {
        width: 240px;
        margin-top: 15px;
    }
}

@media only screen and (max-width: 800px) {
    .detailsBlockAI {
        align-items: center;
        width: 350px;
        margin-top: 200px;
    }

    #genArchDescriptionAIDesktop {
        display: none;
    
    }

    #genArchDescriptionAIPhone {
        font-size: 13px;
        width: 350px;
        margin-top: 220px;
        height: 200px;
    }

    #genArchDescriptionAIPhoneArch {
        font-size: 13px;
        width: 350px;
        margin-top: 370px;
        height: 400px;
    }

    .descImgAI{
        display: flex;
        align-items: center;
        /* Aligns items vertically in the center */
        justify-content:space-around;
        /* Space between the text and the button */
       
        /* Takes full width of the parent */
        margin-bottom: 40px;
    }

    .ImgContainerAI {
        display: flex;
        flex: 1;
        justify-content: center;
        width: 100%;
        margin-top: 0vh;
        margin-bottom: 0vh;
    }

    #imageAI {
        width: 300px;

    }

    .buttonsAI {
        margin-bottom: 50px;
    }

}

@media only screen and (max-width: 450px) {
    #genResTitle {
        font-size: 35px;
    }

    #subTitleAI {
        font-size: 14px;
        margin-top: -5px;
        margin-bottom: 30px;
    }
    .detailsBlockAI {
        align-items: center;
        width: 330px;
        margin-top: 100px;
    }
    #genArchDescriptionAIPhone {
        font-size: 13px;
        width: 320px;
        margin-top: 220px;
        height: 200px;
    }

    #genArchDescriptionAIPhoneArch {
        font-size: 13px;
        width: 320px;
        margin-top: 370px;
        height: 400px;
    }
}