.file-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#archive {
    width: 300px;
    text-decoration: none;
    align-self: center;
}

#aTitle {
    text-align: center;
    font-size: 13px;
    color: black;
}

#a1 {
    margin-top: 15px;
    text-align: left;
    font-size: 12px;
    color: black;
    width: 350px;
}

#a2 {
    margin-top: 10px;
    text-align: right;
    margin-right: -320px;
    color: #6563FF;
    font-weight: 600;
}


@media only screen and (max-width: 1000px) {
    #archive {
        width: 250px;
    }
    #a1 {
        width: 280px;
        font-size: 12px;
    }
    
}

@media only screen and (max-width: 800px) {
   
}

@media only screen and (max-width: 700px) {
   

}

@media only screen and (max-width: 450px) {
    #a2 {
        margin-top: 10px;
        text-align: right;
        margin-right: -260px;
    }
    #archive {
        margin-top: -6px;
    }

}
@media only screen and (max-width: 400px) {
    #archive {
        width: 220px;
    }
    #a1{
        width: 280px;
    }
    #a2 {
        margin-top: 0px;
        margin-right: -220px;
    }

}

@media only screen and (max-height: 700px) {
   

}