/* General styles remain the same */
body {
    margin: 0;
    background-color: white;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}


.container {
    /* Flex-direction column is not necessary here unless you have other design needs */
    align-items: center;
}


.buttons {
    display: flex;
    flex-direction: column;
    /* Stacks flex items vertically */
    gap: 10px;
    /* Adds space between the buttons */
    margin-top: 2vh;
    margin-left: 4vw;
    margin-right: 4vw;
}

.btn {
    text-align: left;
    height: 40px;
    border-width: 0;
    border-radius: 10px;
    color: black;
    cursor: pointer;
    align-items: center;
    /* This aligns the text vertically */
    background-color: #F3F3F3;
}

#id {
    flex: 1
}

#title,
#subTitle {
    text-align: left;
    margin-left: 4vw;
    margin-right: 4vw;
}

#title {
    font-size: 100px;
    font-weight: 600;
    margin-bottom: 0px;
}

#subTitle {
    font-weight: 500;
    font-size: 19px;
}

.purple-circle {
    position: fixed;
    top: 70px;
    right: 50px;
    width: 10px;
    height: 10px;
    background-color: #6563FF;
    border-radius: 50%;
    z-index: 1000;
}

.split {
    display: flex;
}

.p1,
.p2 {
    flex: 1;
    margin-bottom: 0px;
}

.vline {
    height: 300px;
    width: 1px;
    background-color: #F4F4F4;
    position: absolute;
    top: 490px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.appPreview {
    display: flex;
    /* Enables flexbox */
    justify-content: left;
    /* Spreads the children evenly */
    align-items: center;
    /* Aligns children vertically in the center */
    display: flex;
    flex: 1;
    justify-content: center;
    width: 100%;
    margin-top: 6vh;
    margin-bottom: 10vh;
    height: 40vh;
    
}


.logoContianer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;
    margin-top: 4px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    /* Mimics TouchableOpacity */
    width: 70px;
    /* 88% of the viewport width */
    height: 70px;
    border-radius: 12px;
    cursor: pointer;
}

#appIcon {
    width: 60px;
    /* 88% of the viewport width */
}

.appPreviewText {
    margin-left: 20px;
    cursor: pointer;
}

#APTTitle {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0px;
}

#APTSTitle {
    margin-top: 8px;
    font-size: 11px;
    width: 200px;
}

.f-container {
    display: flex;
    flex: 1;
    justify-content: center;
    width: 100%;
    margin-top: 6vh;
    margin-bottom: 10vh;
}


#btnTxt {
    margin-left: 10px;
    font-weight: 500;
    font-size: 13px;
}

.Join {
    display: flex;
    justify-content: center;
    /* Centers children horizontally in the container */
    align-items: center;
    /* Optional: Aligns children vertically in the middle */
    height: 10vh;
    /* Makes the container full height of the viewport */
}

.waitlist {
    padding: 20px;
    /* Adds some spacing around your button */
}


#EA {
    text-decoration: none;
    background-color: white;
    border-color: #D9D9D9;
    border-radius: 14px;
    border-style: solid;
    font-size: 14px;
    text-decoration: none;
    color: black;
    cursor: pointer;
    width: 450px;
    height: 7vh;
}

.more span {
    display: block;
    font-size: 14px;
    line-height: 3vh;
    margin-left: 2vw;
    font-style: italic;
}


@media only screen and (max-width: 800px) {
    .container {
        margin-top: -30px;
    }

    .split {
        flex-direction: column;

    }

    .p1,
    .p2 {
        flex: none;
        /* This removes the flex: 1 setting */
        width: auto;
        /* Allows the div to shrink to fit its content */
    }

    .buttons {
        flex-direction: column;
        /* Stacks the buttons vertically */
    }

    .f-container {
        margin-top: 2vh;
        margin-bottom: 0px;
        /* Adjusts the margin for the layout */
    }

    .appPreview {
        height: 5vh;
    }

    .vline {
        display: none;
    }

    .hline {
        border-top: 1px solid #F4F4F4;
        width: 300px;
        margin: 20px auto;
        height: 0;
    }

    #EA {
        width: 80vw;

    }
}

@media only screen and (max-width: 450px) {
    #title {
        font-size: 70px;
        margin-top: 80px;
    }

    #subTitle {
        font-size: 14px;
    }

    .purple-circle {
        top: 30px;
        right: 30px;
    }

    .split {
        flex-direction: column;
    }

    .p1,
    .p2 {
        flex: none;
        /* This removes the flex: 1 setting */
        width: auto;
        /* Allows the div to shrink to fit its content */
    }

    .buttons {
        flex-direction: column;
        /* Stacks the buttons vertically */
    }


    #EA {
        width: 90vw;
        height: 7.5vh;
    }

    .more span {
        display: block;
        font-size: 14px;
        line-height: 4vh;
        margin-left: 8vw;
    }

}

@media only screen and (max-height: 700px) {


    .waitlist {
        padding-bottom: 5vh;
    }

    #EA {
        width: 90vw;
        height: 9vh;
    }
}

@media only screen and (max-height: 700px) {
    .waitlist {
        height: 7vh;
    }
}