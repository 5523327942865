.next2WFRing {
    margin-top: 2vh;
    margin-left: 360px;
}

.p1WF {
margin-top: -30px;
}

@media only screen and (max-width: 800px) {
    .next2WFRing {
        margin-left: calc(87vw - 50px);
    }
}

@media only screen and (max-width: 450px) {
    .next2WFRing {
        margin-left: calc(87vw - 50px);
    }

    .p1WF {
        margin-top: -155px;
        }
}