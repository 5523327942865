html,
body {
    height: 100%;
    margin: 0;
    padding: 0;

}



/*Title*/

.TitleEA {
    display: flex;
    flex-direction: column;
    /* Ensures children stack vertically */
    align-items: center;
    /* Align children to the start (left) */
    margin-top: -90px;
}

#EAt1 {
    margin-bottom: 0px;
    font-weight: 550;
}

#EAst1 {
    margin-top: 5px;
    font-weight: 400;
}


.p2EA {
    min-height: 34.5vh;
    /* Adjust the height based on your typical content height */
}


/*option page*/
.OptionsEAContainer {
    margin-top: -90px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* Adjust the gap between the elements as needed */
}

#SelectOneEA {
    font-size: 14px;
    font-weight: 500;
}

.loginEA {
    font-size: 14px;
    font-weight: 500;
    color: #6563FF;
    cursor: pointer;
    display: flex;
}

.WaitlistEA {
    background-color: white;
    border-color: #D9D9D9;
    border-radius: 14px;
    border-style: solid;
    border-width: 1px;
    width: 400px;
    cursor: pointer;
}

.WaitlistInputEA {
    background-color: white;
    border-color: #D9D9D9;
    border-radius: 14px;
    border-style: solid;
    border-width: 1px;
    width: 394px;
    height: 50px;
    padding: 0px 16px;
}

.WaitlistInputEA:focus {
    border-color: #6563FF;
    border-width: 2px;
    outline: none;
}

#WaitlistPlaceholderEA {
    margin-left: 20px;
    font-size: 15px;
    font-weight: 500;
}

#WaitlistPlaceholderSmallerEA {
    font-size: 14px;
    font-weight: 400;
}

.PremiumEA {
    background-color: #F3F3F3;
    border-radius: 14px;
    width: 400px;
    height: 23vh;
    font-size: 15px;
    cursor: pointer;
    position: relative;
}

#LAEA {
    margin-left: 20px;
    padding-top: 10px;
    margin-bottom: 0px;
    font-size: 12px;
    color: #E1B416;
    font-weight: 500;
}

#ReserveTitleEA {
    margin-top: 10px;
    margin-left: 35px;
    font-weight: 500;
}

.featuresEA span {
    display: block;
    font-size: 12px;
    line-height: 3vh;
    margin-left: 45px;
    margin-right: 1.1vw;
}

.actionButtEA {
    position: absolute;
    bottom: 0;
    right: 0;
}

#actionButtJEA {
    background-color: #6563FF;
    font-size: 13px;
    color: white;
    padding: 5px 12px;
    border-radius: 9px;
    margin-right: 15px;
    margin-top: 0vh;
}



/*Purchase page1 */

.Reserve1EAContainer {
    margin-top: -145px;
    display: flex;
    flex-direction: column;
    margin-right: 10vw;
    /* Adjust the gap between the elements as needed */
}

.Reserve1WContainer {
    margin-top: -140px;
    display: flex;
    flex-direction: column;

    margin-right: 10vw;
    /* Adjust the gap between the elements as needed */
}

.HeaderEAContainer {
    display: flex;
    align-items: center;
    /* Center items vertically within the container */
    justify-content: space-between;
    /* Aligns children at the ends and space between them */
    width: 400px;
    /* Ensures the container takes full available width */
}


.HeaderEAContainer,
.JoinEA-email,
.JoinEA-password,
.next2EA,
.next2EARing,
.next2WRing {
    flex: 0 0 auto;
    /* Do not grow, do not shrink, auto basis */
}

ul {
    flex: 1 0 auto;
    /* Grow if necessary, do not shrink, auto basis */
    font-size: 13px;
    line-height: 20px;
}

#reqTitle {
    font-size: 14px;
    font-weight: 500;
}

.JoinEA-name {
    background-color: #F3F3F3;
    border-color: #F3F3F3;
    border-radius: 14px;
    border-style: solid;
    border-width: 1px;
    width: 400px;
    cursor: pointer;
    padding: 8px 16px;
    box-sizing: border-box;
    height: 55px;

}

.JoinEA-name:focus {
    border-color: #6563FF;
    border-width: 2px;
    outline: none;
}

#AdditionalTextEA {
    font-size: 14px;
    font-weight: 500;
}

.next1EA {
    margin-left: 345px;
    height: 100%;
    border: none;
    background-color: #6563FF;
    font-size: 13px;
    color: white;
    border-radius: 9px;
    margin-top: 2vh;
    width: 50px;
    height: 25px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    padding-left: 10px;
    /* Adjust the padding as needed */
    padding-right: 10px;
    /* Adjust the padding as needed */
}

.WaitlistJoin {
    margin-left: 375px;
    height: 100%;
    border: none;
    background-color: #6563FF;
    font-size: 13px;
    color: white;
    border-radius: 9px;
    margin-top: 2vh;
    width: 50px;
    height: 25px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    padding-left: 10px;
    /* Adjust the padding as needed */
    padding-right: 10px;
    /* Adjust the padding as needed */
}




/* purchase page2*/

.JoinEA-email,
.JoinEA-password {
    background-color: #F3F3F3;
    border-color: #F3F3F3;
    border-radius: 14px;
    border-style: solid;
    border-width: 1px;
    width: 400px;
    cursor: pointer;
    padding: 8px 16px;
    box-sizing: border-box;
    height: 55px;
}

.JoinEA-password {
    margin-top: 10px;
}

.next2EA {
    /* Ensure the height is set appropriately */
    margin-left: 350px;
    height: 100%;
    border: none;
    background-color: #6563FF;
    font-size: 13px;
    color: white;
    border-radius: 9px;
    margin-top: 2vh;
    width: 50px;
    height: 25px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    padding-left: 10px;
    /* Adjust the padding as needed */
    padding-right: 10px;
    /* Adjust the padding as needed */
}

.tcWarning {
    display: flex;
    justify-content: center;
    margin-top: -30vh;
    margin-bottom: 30vh;
}

.tcWarning a {
    font-size: 13px;
    color: #616161;
    text-decoration: none;
}


.next2EARing {
    /* Ensure the height is set appropriately */
    margin-left: 350px;
    height: 100%;
    border: none;
    margin-top: 2vh;
    display: inline-block;
    text-align: center;
    padding-left: 10px;
    /* Adjust the padding as needed */
    padding-right: 10px;
    /* Adjust the padding as needed */
}

.next2WRing {
    margin-top: 2vh;
    margin-left: 390px;
}

.JoinEA-email:focus,
.JoinEA-password:focus {
    border-color: #6563FF;
    border-width: 2px;
    outline: none;
}

.p1ResEA {
    margin-top: -60px;
}

.p1WEA {
    margin-top: -56px;
}

.p1LEA {
    margin-top: -56px;
}

@media only screen and (max-width: 800px) {

    .OptionsEAContainer,
    .WaitlistEA,
    .PremiumEA,
    .JoinEA-name,
    .JoinEA-email,
    .JoinEA-password,
    .WaitlistInputEA {
        width: 100%;
        /* Adjust width */
    }

    /*title*/
    #EAt1 {
        font-weight: 550;
        font-size: 25px;
    }

    #EAst1 {
        font-size: 17px;
    }

    .featuresEA span {
        display: block;
        font-size: 13px;
        line-height: 3vh;
        margin-left: 8vw;
        margin-right: 5vw;
    }

    .OptionsEAContainer {
        margin-top: 0px;
    }

    .TitleEA {
        align-items: flex-start;
        margin-top: -25vh;
        margin-left: 7vw;
    }

    .WaitlistEA {
        background-color: white;
        border-color: #D9D9D9;
        border-radius: 14px;
        border-style: solid;
        border-width: 1px;
        width: 100%;
    }

    

    .PremiumEA {
        background-color: #F3F3F3;
        border-radius: 14px;
        width: 100%;
        font-size: 15px;
        height: 30vh;
    }

    .OptionsEAContainer {
        width: 87%;
    }

    .p2EA {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -8vh;
    }

    .featuresEA span {
        line-height: 4vh;
    }

    #actionButtJEA {
        margin-right: 15px;
    }

    .Reserve1EAContainer {
        margin-top: 0px;
        display: flex;
        align-items: center;
        margin-left: 10vw;
        /* Adjust the gap between the elements as needed */
    }

    .Reserve1WContainer {
        margin-top: 0px;
        display: flex;
        align-items: center;
        margin-left: 10vw;
        /* Adjust the gap between the elements as needed */
    }

    .JoinEA-name,
    .JoinEA-email,
    .JoinEA-password,
    .createAccountButtcontainer {
        width: 85vw;
    }

    .WaitlistInputEA {
        width: 80vw;
    }

    .next1EA {
        margin-left: calc(85vw - 52.06px);
    }


    .next2EA {
        margin-left: calc(85vw - 50px);
    }

    .WaitlistJoin {
        margin-left: calc(80vw - 18px);
    }

    .next2EARing {
        margin-left: calc(85vw - 50px);
    }
 
    .next2WRing {
        margin-left: calc(85vw - 50px);
    }

    .HeaderEAContainer {
        width: 85vw;
        display: flex;
        align-items: center;
        margin-right: 0vw;
    }

    .HeaderEAContainer p {
        white-space: nowrap;
    }

    .p1ResEA {
        margin-top: 1px;
    }

    .p1EA {
        margin-top: 130px;
    }

    .p1WEA {
        margin-top: 2px;
    }
    
    .p1LEA {
        margin-top: 2px;
    }

}

@media only screen and (max-width: 450px) {
    .featuresEA {
        margin-top: -1vh;
        margin-left: 1.5vh;
    }

    .p1ResEA {
        margin-top: -148px;
    }

    .p1EA {
        margin-top: -20px;
    }
    .p1WEA {
        margin-top: -151px;
    }

    .p1LEA {
        margin-top: -150px;
    }
}

@media only screen and (max-height: 900px) {}

@media only screen and (max-height: 700px) {}