.footer {
    width: 100%;
    background-color: white;
    color: #616161;
    text-align: right;
    margin-top: 40px;
height: 50px;
    /* Removed fixed positioning */
}

.footer-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 50px;
}

.footer a {
    color: #616161;
    text-decoration: none;
    padding: 0 15px;
    font-size: 13px;
}