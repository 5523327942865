
#NameAcc {
    text-align: left;
    margin-left: 4vw;
    margin-right: 4vw;
    font-size: 55px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 130px;
    word-wrap: break-word; /* Ensure long words will break and wrap to the next line */
  word-break: break-all; /* Allow breaks within words to prevent overflow */
  overflow-wrap: break-word; /* Provide fallback support for older browsers */
}

#dateJoinedAcc,
#settingsAcc {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    margin-top: 0px;
    cursor: pointer;
    text-decoration: none;
    color: black;
    background-color: white;
    border-style: none;
}

.subContainerAcc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 4vw;
    margin-right: 4vw;
}

/* Reservations section */
.ReservationsAcc {
    width: 400px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 140px;
    height: 46vh;
    margin-bottom: 20vh;
}

.ReservationsTitleAcc p {
    font-size: 13px;
    font-weight: 500;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.ArchiveDetailsAcc {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.ArchiveItemAcc {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ArchiveTitleAcc {
    font-size: 13px;
    margin-bottom: 5px;
}

#archiveAcc {
    width: 150px;
    height: auto;
}

.ArchiveDescriptionAcc {
    font-size: 13px;
    margin-top: 30px;
    margin-left: 25px;
}
#descriptionAcc1{
    font-size: 12px;

}
.ReservedStatusAcc {
    text-align: right;
    font-size: 14px;
    color: #6563FF;
}

.settingsContainer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end; 
    margin-right: 4vw;
}

#singOut {
    text-decoration: none;
    color: black;
    font-size: 13px;
    background-color: white;
    border-style: none;
    cursor: pointer;
}

#delete {
    text-decoration: none;
    color: red;
    font-size: 13px;
    background-color: white;
    border-style: none;
    cursor: pointer;
}

@media only screen and (max-width: 450px) {
    .ReservationsAcc {
        width: 91%;
        margin: 0 auto;
        padding: 20px;
        margin-top: 10px;
        height: 59vh;
        margin-bottom: 20vh;
    }
    #archiveAcc {
        width: 120px;
    }
    #NameAcc {
        font-size: 45px;
        margin-top: 100px;
    }
}