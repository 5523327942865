.hide-video {
    display: none;
}

.fade-in {
    display: block;
    animation: fadeIn 1s forwards;
}


/* Define fade-out animation */
@keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  /* Apply the fade-out animation */
  .fade-out {
    animation: fadeOut 1s forwards;
  }