html,
body {
    height: 100%;
    /* Ensures the full height for the body */
    margin: 0;
    /* Resets default margin */
    padding: 0;
    /* Resets default padding */
}


.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 62px;
    padding-right: 50px;
    padding-left: 50px;
    position: fixed;
    top: 0;
    width: 100%;
    box-sizing: border-box;

}

.containerIA {
    display: flex;
    /* Using flexbox */
    flex-direction: column;
    /* Children are stacked vertically */
    justify-content: center;
    /* Centers children vertically in the container */
    /* Full viewport height minus the top-bar height */
    margin-top: 40px;
    /* Offset for the fixed top-bar */
    min-height: 100vh;
}

.back-button {
    cursor: pointer;
    border-width: 0;
    padding: 5px 15px;
    border-radius: 6px;
    color: black;
    background-color: #f3f3f3;
}

.purp-circle {
    width: 10px;
    /* Adjusted to be visible as a circle */
    height: 10px;
    background-color: #6563FF;
    border-radius: 50%;
}

.ImgContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    width: 100%;
    margin-top: 10vh;
    margin-bottom: 10vh;

}

.genResContainer {
    display: flex;
    align-items: center;
    /* Aligns items vertically in the center */
    justify-content: space-between;
    /* Space between the text and the button */
    width: 440px;
    /* Takes full width of the parent */
    margin-bottom: 10px;
    /* Adds space between this container and the description */
}

.genResContainer2 { 
    display: none;
}

.genArchDescriptionContainer {
    width: 400px;
    /* Ensures description container takes full width */
    align-items: center;
}

.details {
    display: flex;
    flex-direction: column;
    /* Stacks children vertically */
    justify-content: center;
    width: 100%;
    margin-top: 13vh;
    margin-bottom: 10vh;
}

#genResTitle {
    font-size: 26px;
    font-weight: 550;
    margin-top: 0px;
    margin-bottom: 0px;
}

#genResButt {
    background-color: #6563FF;
    font-size: 13px;
    color: white;
    padding: 5px 14px;
    border-radius: 9px;
    margin-right: 15px;
    margin-top: 0vh;
    border-style: none;
    cursor: pointer;
}
#genResButtReserved {
    background-color: white;
    font-size: 13px;
    color: #6563FF;
    padding: 5px 14px;
    border-radius: 9px;
    margin-top: 0vh;
    border-style: none;
}

#genArchDescription {
    font-size: 13px;
    margin-left: 30px;
}

.fullAccessContainer{
    display: block;
    background-color: #EDEDED;
    font-size: 13px;
    color: #616161;
    padding: 10px 25px;
    border-radius: 15px;
    margin-top: 8vh;
    width: 300px;
    align-self: center;
}
#fullAccess{
    text-align: center;
}
@media only screen and (max-width: 1000px) {
    .genResContainer {
        width: 380px;
    }

    .genArchDescriptionContainer {
        width: 360px;
    }

    #genArchDescription {
        margin-left: 15px;
        font-size: 12px;
    }

    .details {
        margin-top: 12vh;
    }
    #genResTitle { 
        font-size: 23px;
    }
}

@media only screen and (max-width: 800px) {
    .details {
        align-items: center;

    }

    .genResContainer{
        display: none;
    }

    .genResContainer2 { 
        display: flex;
        align-items: center;
        /* Aligns items vertically in the center */
        justify-content:space-around;
        /* Space between the text and the button */
       
        /* Takes full width of the parent */
        margin-bottom: 40px;
    }
    .ImgContainer {
        display: flex;
        flex: 1;
        justify-content: center;
        width: 100%;
        margin-top: 0vh;
        margin-bottom: 0vh;
    
    }

    
    #genResTitle2 {
        font-size: 26px;
        font-weight: 550;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    
    #genResButt2 {
        background-color: #6563FF;
        font-size: 13px;
        color: white;
        padding: 5px 14px;
        border-radius: 9px;
        margin-top: 0vh;
        border-style: none;
        cursor: pointer;
    }
    #genResButtReserved2 {
            background-color: white;
            font-size: 13px;
            color: #6563FF;
            padding: 5px 14px;
            border-radius: 9px;
            margin-top: 0vh;
            border-style: none;
    }

    .genArchDescriptionContainer {
       margin-top: -90px;
    }

    .fullAccessContainer{
        margin-top: 0px;
    }
}

@media only screen and (max-width: 450px) {
    .top-bar {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 22px;
    }

    .back-button {
        font-size: 13px;
        font-weight: 400;
        border-radius: 8px;
        margin-left: -2.5vw;

    }

    .containerIA {
        margin-top: 70px;
    }

    .genArchDescriptionContainer {
        width: 330px;
        margin-top: -70px
    }
}