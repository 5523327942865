.text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #616161;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .text-content {
    max-width: 600px;
    text-align: left;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  

  