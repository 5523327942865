.feedbackInput {
    background-color: #F3F3F3;
    border-color: #F3F3F3;
    border-radius: 14px;
    border-style: solid;
    border-width: 1px;
    width: 400px;
    height: 23vh; /* Adjust height as needed */
    cursor: text; /* Changed from pointer to text */
    padding: 16px 18px;
    box-sizing: border-box;
    resize: none; /* Optional: prevent resizing */
    overflow: auto; /* Ensures scrollbar appears when text overflows */
    vertical-align: top; /* Aligns text at the top */
    font-family: sans-serif; /* System default fonts */
}

.feedbackInput:focus {
    border-color: #6563FF;
    border-width: 2px;
    outline: none;
}

.next2Suc {
    /* Ensure the height is set appropriately */
    margin-left: 335px;
    height: 100%;
    border: none; 
    background-color: #6563FF;
    font-size: 13px;
    color: white;
    border-radius: 9px;
    margin-top: 2vh;
    width: 65px;
    height: 25px;
    cursor: pointer;
    display: inline-block; 
    text-align: center;
    padding-left: 10px; /* Adjust the padding as needed */
    padding-right: 10px; /* Adjust the padding as needed */
}

#ViewAccSuc{
    cursor: pointer;
    border: none; 
    background-color: white;
    color: #6563FF;
    font-size: 14px;
    margin-top: 90px;
}

.viewAccSucContainer {
    display: flex;      /* Enables flexbox layout */
    justify-content: center; /* Centers content horizontally in the flex container */
    align-items: center; /* Optional: aligns items vertically in the center, if needed */
    width: 100%;       /* Takes full width of its parent, ensuring centering within any parent size */
    margin-top: 20px;   /* Optional: adds some space above the container */
}

#ThankYou {
    font-size: 15px;
    font-weight: 500;
}

@media only screen and (max-width: 800px) {
    .feedbackInput {
        width: 87vw;
        height: 35vh; /* Adjust height as needed */
    }

    .next2Suc {
        margin-left: calc(87vw - 66px);
    }

    #ThankYou {
        text-align: center;
        font-weight: 400;
    }
}

